import React, { useState } from 'react';
import Container from '../../components/Container';
import Layout from "../../components/layouts"
import Backbar from "../../components/navigation/backbar"

import { useForm } from 'react-hook-form'
import { GraphQLClient, gql } from 'graphql-request';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';

const client = new GraphQLClient('https://classify.space/?graphql=true')

const MUTATION = gql`
mutation CreateSubmission($clientMutationId: String!, $firstName: String!, $email: String!, $topic: String!, $message: String!) {
  createFormSubmission(input: {clientMutationId: $clientMutationId, email: $email, firstName: $firstName, message: $message, topic: $topic}) {
    data
    success
  }
}
`

function Contact({location}) {

  const { register, handleSubmit, formState: { errors } } = useForm();

  const [state, setState] = useState(null)
  const [loading, setLoading] = useState(false);

  const onSubmit = async data => {
    if (state?.createFormSubmission) return

    setLoading(true)
    const res = await client.request(MUTATION, {
      clientMutationId: 'example', 
      firstName: data['First name'],
      email: data['Email'],
      topic: data['Topic'],
      message: data['Message']
    })
    setState(res)
    setLoading(false)
  };

  return (
    <>
    <Helmet>
      <title>Classify — Contact</title>
      <meta name="description" content="Find help with the app, its features and the company itself." />
      <link rel="canonical" href="https://classify.org.uk/support/contact" />

      <meta property="og:title" content="Classify — Contact"/>
      <meta property="og:url" content="https://classify.org.uk/support/contact"/>
      <meta property="og:image" content="https://i.imgur.com/ODTJzzm.png"/>
      <meta property="og:description" content="Find help with the app, its features and the company itself."/>
      <meta property="og:type" content="website"/>

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:site" content="@classifyapp"/>
      <meta property="twitter:title" content="Classify — Contact"/>
      <meta name="twitter:image" content="https://i.imgur.com/ODTJzzm.png"/>
      <meta property="twitter:description" content="Find help with the app, its features and the company itself."/>
    </Helmet>
    <Layout>
      <Backbar>{location?.state?.referrer || 'Support'}</Backbar>
      <Container no-vertical>
        <div className="grid gap-12 text-center pt-24 pb-12 lg:py-24">
          <h1 className="font-valorant">Contact Us</h1>
          <p className="text-xl">Fill out the form below to get in touch with the team.</p>
          <div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full xs:w-4/5 lg:w-3/5 2xl:w-2/5 mx-auto grid gap-4 relative">

              {state?.createFormSubmission.success === true && 
                <div className="absolute inset-0 flex flex-col items-center justify-start bg-white">
                  <p className="text-lg font-bold">Your response was recorded - thank you for getting in touch!</p>
                  <button onClick={() => navigate(-1)} className="mt-6">Take me back!</button>
                </div>
              }

              {state?.createFormSubmission.success === false && 
                <div className="absolute inset-0 flex flex-col items-center justify-start bg-white">
                  <p className="text-lg font-bold">An unexpected error occurred.</p>
                  <button onClick={() => navigate(-1)} className="mt-6">Take me back!</button>
                </div>
              }

              <div data-error={errors['First name']}>
                <label htmlFor="name">First Name</label>
                <input id="name" type="text" placeholder="First name" {...register("First name", {required: true, maxLength: 80})} />
                {errors['First name']?.type === 'required' && <span>Your name is required.</span>}
              </div>
              <div data-error={errors['Email']}>
                <label htmlFor="email">Email</label>
                <input id="email" type="text" placeholder="Email" {...register("Email", {required: true, pattern: /^\S+@\S+$/i})} />
                {errors['Email']?.type === 'required' && <span>Your email is required.</span>}
                {errors['Email']?.type === 'pattern' && <span>Please enter a valid email.</span>}
              </div>
              <div data-error={errors['Topic']}>
                <label htmlFor="topic">Topic</label>
                <select id="topic" {...register("Topic", { required: true })} defaultValue={location.search !== '' ? 'Feature Request' : ''}>
                  <option value="" disabled>Select a topic</option>
                  <option value="Bug Report">Bug Report</option>
                  <option value="Feature Request">Feature Request</option>
                  <option value="Other">Other</option>
                </select>
                {errors['Topic']?.type === 'required' && <span>Please select a topic.</span>}
              </div>
              <div data-error={errors['Message']}>
                <label htmlFor="message">Message</label>
                <textarea id="message" {...register("Message", {required: true})} />
                {errors['Message']?.type === 'required' && <span>Your message is required.</span>}
              </div>
              <div className="pt-6">
                <div className="inline"><button formAction="submit" disabled={state?.createFormSubmission || loading}>{loading ? 'Loading' : 'Submit'}</button></div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </Layout>
    </>
  );
}

export default Contact;
